<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="3" cols="12">
        <process-card :data="data.processes" />
      </b-col>
      <b-col lg="3" cols="12">
        <tasks :data="data.lateTasks" />
      </b-col>
      <b-col lg="3" cols="12">
        <roles :data="data.roles" />
      </b-col>
      <b-col lg="3" cols="12">
        <user-card :data="data.users" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
  import { BRow, BCol } from 'bootstrap-vue'

  import { mapActions, mapState } from 'vuex'
  import UserCard from '@/views/dashboard/statsCard/UserCard.vue'
  import Roles from '@/views/dashboard/statsCard/OrgRolesCard.vue'
  import Tasks from '@/views/dashboard/statsCard/Tasks.vue'
  import ProcessCard from './statsCard/ProcessCard.vue'

  export default {
    components: {
      BRow,
      BCol,
      Roles,
      Tasks,
      ProcessCard,
      UserCard,
    },
    data() {
      return {
        data: {},
      }
    },
    computed: {
      ...mapState('stats', ['organizationStatistics', 'isLoading']),
    },
    created() {
      this.getOrganizationStatistics().then(() => {
        this.data = this.organizationStatistics
      })
    },
    methods: {
      ...mapActions('stats', [
        'fetchOrganizationStatisticsIfNotLoaded',
        'getOrganizationStatistics',
      ]),
    },
  }
</script>

<style lang="scss">
  @import '../../@core/scss/vue/pages/dashboard-ecommerce';
  @import '../../@core/scss/vue/libs/chart-apex';
</style>
